#cookieNotice {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #333333;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
  transform: translateY(100%);
  transition: all 500ms;
}
@media (min-width: 780px) {
  #cookieNotice {
    flex-direction: row;
  }
}
#cookieNotice.showing {
  transform: translateY(0);
}
#cookieNotice a {
  color: white;
  text-decoration: underline;
}
#cookieNoticeText {
  font-size: 12px;
  line-height: 20px;
  flex: 1;
  margin: 20px 0 20px 0;
  padding: 0 20px;
}
@media (min-width: 780px) {
  #cookieNoticeText {
    font-size: 14px;
    line-height: 24px;
    padding: 0 0 0 calc(25vw - 165px);
  }
}
@media (min-width: 960px) {
  #cookieNoticeText {
    padding-left: calc(25vw - 190px);
  }
}
#cookieNoticeButtons {
  flex: none;
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 0 10px;
}
@media (min-width: 520px) {
  #cookieNoticeButtons {
    padding: 0 20px;
  }
}
@media (min-width: 780px) {
  #cookieNoticeButtons {
    font-size: 16px;
    margin-bottom: 0;
  }
}
#cookieNoticeLink {
  padding-left: 20px;
  order: 2;
}
@media (min-width: 780px) {
  #cookieNoticeLink {
    order: 1;
    padding-left: 30px;
    padding-right: 30px;
  }
}
#cookieNoticeAccept {
  display: inline-block;
  margin: 0;
  padding: 0 20px;
  cursor: pointer;
  color: white;
  background: #29AA00;
  height: 32px;
  line-height: 32px;
  border-radius: 20px;
  order: 1;
  transition: background-color 300ms;
}
#cookieNoticeAccept:hover {
  background: #54bb33;
}
@media (min-width: 780px) {
  #cookieNoticeAccept {
    padding: 0 30px;
    height: 40px;
    line-height: 40px;
    order: 2;
  }
}
#cookieNoticeDismiss {
  position: relative;
  width: 26px;
  height: 26px;
  padding: 20px;
  margin-left: 16px;
  cursor: pointer;
  order: 3;
}
#cookieNoticeDismiss:before,
#cookieNoticeDismiss:after {
  position: absolute;
  left: 19px;
  top: 10px;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: white;
}
#cookieNoticeDismiss:before {
  transform: rotate(45deg);
}
#cookieNoticeDismiss:after {
  transform: rotate(-45deg);
}
@media (min-width: 780px) {
  #cookieNoticeDismiss {
    margin-left: 26px;
    margin-right: 6px;
  }
}
