@import "base";

@min-s: ~"(min-width: 520px)";
@min-m: ~"(min-width: 780px)";
@min-l: ~"(min-width: 960px)";
@min-xl: ~"(min-width: 1200px)";

#cookieNotice {
  .font-light();
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: @color-primary;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
  transform: translateY(100%);
  transition: all 500ms;

  @media @min-m {
    flex-direction: row;
  }

  &.showing {
    transform: translateY(0);
  }

  a {
    color: white;
    text-decoration: underline;
  }

  &Text {
    font-size: 12px;
    line-height: 20px;
    flex: 1;
    margin: 20px 0 20px 0;
    padding: 0 20px;

    @media @min-m {
      font-size: 14px;
      line-height: 24px;
      padding: 0 0 0 calc(25vw - 165px);
    }

    @media @min-l {
      padding-left: calc(25vw - 190px);
    }
  }

  &Buttons {
    flex: none;
    align-items: center;
    display: flex;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 0 10px;

    @media @min-s {
      padding: 0 20px;
    }

    @media @min-m {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  &Link {
    padding-left: 20px;
    order: 2;

    @media @min-m {
      order: 1;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &Accept {
    display: inline-block;
    margin: 0;
    padding: 0 20px;
    cursor: pointer;
    color: white;
    background: @green;
    height: 32px;
    line-height: 32px;
    border-radius: 20px;

    order: 1;
    transition: background-color 300ms;

    &:hover {
      background: tint(@green, 20%);
    }

    @media @min-m {
      padding: 0 30px;
      height: 40px;
      line-height: 40px;
      order: 2;
    }
  }

  &Dismiss {
    position: relative;
    width: 26px;
    height: 26px;
    padding: 20px;
    margin-left: 16px;
    cursor: pointer;
    order: 3;

    &:before, &:after {
      position: absolute;
      left: 19px;
      top: 10px;
      content: ' ';
      height: 20px;
      width: 2px;
      background-color: white;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    @media @min-m {
      margin-left: 26px;
      margin-right: 6px;
    }
  }
}
